<template>
    <div>
        <div
            :class="[
                {
                    'hide-scroll':
                        !showOverflowProductionProgress || modal.opened,
                },
            ]"
            id="production-progress"
        >
            <AppointmentHeader
                :component-id="'appointment-screen'"
                :back-label="headerBackLabel"
                :back-event="headerBackEvent"
                :orders="headerOrders"
                :status="headerStatus"
                :color="headerColor"
                @removeAppointment="removeAppointmentFromMultiple"
            />

            <main class="content-container">
                <div
                    :class="[
                        'activity-cards',
                        moreThanOneCardShowing ? 'two-rows' : 'single-row',
                    ]"
                >
                    <ActivityCard
                        v-if="cards.setup.show"
                        :title="$t('ProductionProgress.Setup')"
                        type="setup"
                        :show-add-button="false"
                        :active="cards.setup.active"
                        :datetime="cards.setup.datetime"
                        :timer="timer.card === 'setup' ? timer.data : null"
                        @handleClick="openAppointmentModal('Setup')"
                    />
                    <ActivityCard
                        v-if="cards.startPartial.show"
                        :title="
                            timer.card === 'stop'
                                ? $t('ProductionProgress.Continue')
                                : $t('ProductionProgress.Start')
                        "
                        :custom-active-label="$t('ProductionProgress.Partial')"
                        type="start"
                        show-add-button
                        :active="cards.startPartial.active"
                        :datetime="cards.startPartial.datetime"
                        :timer="timer.card === 'start' ? timer.data : null"
                        @handleClick="
                            openAppointmentModal(
                                `${
                                    cards.startPartial.active
                                        ? 'Partial'
                                        : 'Start'
                                }`
                            )
                        "
                    />
                    <ActivityCard
                        v-if="cards.stop.show"
                        :title="$t('ProductionProgress.Stop')"
                        type="stop"
                        show-add-button
                        :active="cards.stop.active"
                        :datetime="cards.stop.datetime"
                        :timer="timer.card === 'stop' ? timer.data : null"
                        @handleClick="openAppointmentModal('Stop')"
                    />
                    <ActivityCard
                        v-if="cards.finish.show"
                        :title="
                            cards.finish.active
                                ? $t('ProductionProgress.Finished')
                                : $t('ProductionProgress.Finish')
                        "
                        type="finish"
                        :show-add-button="false"
                        :active="cards.finish.active"
                        :datetime="cards.finish.datetime"
                        @handleClick="
                            cards.finish.active
                                ? ''
                                : openAppointmentModal('Finish')
                        "
                    />
                </div>

                <div id="pdp__button-c">
                    <Button
                        :title="$t('ProductionProgress.OrderDetails')"
                        icon="orderDetails"
                        @handleClick="redirectToOrderDetails"
                    />
                </div>
            </main>
        </div>

        <AppointmentModal
            @removeAppointment="removeAppointmentFromMultiple"
            ref="appointmentModal"
            :allow-change-date="requestDatetime"
            :is-control-by-hour="isControlByHour"
            :order-object="isMultipleOrders ? multiple : order"
            :format-datetime="formatDatetime"
            :default-produced-quantity="remainingQuantity"
            :default-date="defaultDate"
            :default-resource="resource"
            :allow-change-resource="allowChangeResource"
            :resources-list="resourcesList"
            :status-list="statusOptions"
            :rejection-list="refuseStatus"
            :open.sync="modal.opened"
            :title="returnAppointmentModalTitle()"
            :show-toast="showToast"
            :isMultipleOrders="isMultipleOrders"
            :orderListDisplay="
                isMultipleOrders
                    ? multipleOrdersList
                    : listOfNameAndPreactorOfOrders
            "
            @close="closeAppointmentModal"
            @confirmAppointment="confirmAppointment"
            :appointmentType="appointmentType"
        />

        <OrderDetailsModal
            v-if="showModalOrder"
            :open.sync="showModalOrder"
            :orderData="order"
            @close="onCloseDetailsModal"
            :isControlByHour="isControlByHour"
        />
        <OrderDetailsListModal
            v-if="showModalOrderDetailsList"
            :open.sync="showModalOrderDetailsList"
            @close="onCloseDetailsModal"
            :orderList="
                multiple.orders.map((order) => ({
                    preactorOrderId: order.preactor_order_id,
                    orderLabel: `${order.order_no}/${order.op_no}`,
                }))
            "
        />
    </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppointmentModal from "@/views/pages/operator/components/AppointmentModal/AppointmentModal.vue";
import moment from "moment";
import AppointmentHeader from "@/views/pages/operator/components/AppointmentHeader";
import ActivityCard from "./ActivityCard/index.vue";
import Button from "./Button.vue";

export default {
    components: {
        ActivityCard,
        AppointmentHeader,
        Button,
        AppointmentModal,
        ToastificationContent,
        OrderDetailsModal: () =>
            import(
                "@/views/pages/operator/components/OrderModal/OrderModal.vue"
            ),
        OrderDetailsListModal: () =>
            import(
                "@/views/pages/operator/components/OrderListModal/index.vue"
            ),
    },
    computed: {
        headerBackLabel() {
            const { appointment_type } = this.$router.currentRoute.params;
            return appointment_type == 0
                ? this.$t("ProductionProgress.ProductiveNote")
                : this.$t("ProductionProgress.ReworkNote");
        },
        headerOrders() {
            return this.isMultipleOrders
                ? this.multipleOrdersList
                : [
                      {
                          id: 0,
                          name: `${this.order.order_no}/${this.order.op_no}`,
                      },
                  ];
        },
        headerStatus() {
            return this.isMultipleOrders
                ? this.multiple.last_status
                : this.order.last_status_by_user;
        },
        headerColor() {
            if (this.isMultipleOrders) return this.multiple.status_color;
            return this.isControlByHour
                ? this.order.last_color_status_by_user
                : this.order.last_color_status;
        },
        appointmentType() {
            return this.$router.currentRoute.params.appointment_type;
        },
        moreThanOneCardShowing() {
            return (
                Object.keys(this?.cards).filter(
                    (card) => this?.cards?.[card]?.show
                )?.length > 1
            );
        },
    },
    methods: {
        ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
        ...mapActions("app", ["removeRequestLoading"]),
        headerBackEvent() {
            this.$router.push({
                name: "operator-orders-all",
                params: { prefix: localStorage.getItem("prefix") },
            });
        },
        async onCloseDetailsModal(value) {
            this.showModalOrderDetailsList = false;
            this.showModalOrder = false;
            this.showOverflowProductionProgress = true;

            if (!this.isMultipleOrders) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                await this.getOrder(true, this.appointmentType);
                this.setCardsData();
                this.UPDATE_FLAG_SHOW_OVERLAY(false);
            }
        },
        async removeAppointmentFromMultiple(appointmentId) {
            const { preactorsOrdersId } = this;
            const orders = preactorsOrdersId.split(",");
            const params = this.$route.params;
            const updatedOrders = orders.filter(
                (order) => Number(order) !== Number(appointmentId)
            );
            const updatedParams = {
                ...params,
                preactor_orders: updatedOrders.join(","),
            };

            if (updatedOrders.length === 1) {
                // Se tiver somente 1 ordem, redirecionar para apontamento individual
                const resourceId = this.multiple.resource_id;
                const is_rework = this.appointmentType;
                const orderParams = {
                    prefix: params.prefix,
                    return_orders: "true",
                    multiple: "false",
                    order_id: String(updatedOrders[0]),
                    resource_id: resourceId,
                    orders: [],
                    appointment_type: is_rework,
                };

                this.$router
                    .push({
                        name: "operator-production-progress",
                        params: orderParams,
                    })
                    .then(() => {
                        this.$router.go();
                    });

                return;
            }

            this.$router.replace({
                params: updatedParams,
            });

            await this.loadPage(this.appointmentType);
        },
        returnAppointmentModalTitle() {
            const { modal, cards } = this;

            if (
                (modal.type === "Partial" || modal.type === "Start") &&
                cards.stop.active
            )
                return "Continue";

            return modal.type;
        },
        openAppointmentModal(type) {
            this.modal.type = type;
            this.modal.opened = true;
        },
        closeAppointmentModal() {
            this.modal.opened = false;

            this.$nextTick(() => {
                this.modal.type = "";
            });
        },
        getSelectedStatusId(data) {
            const findStatusId = (term) =>
                this.statusList.filter((item) => item.text === term)[0].value;
            const modal = this.modal.type.toLowerCase();

            const currentStatus = (() => {
                if (modal === "stop") return data.status.id;

                if (
                    modal === "start" ||
                    modal === "partial" ||
                    modal === "continue"
                ) {
                    const noActiveCard = !Object.keys(this.cards).some(
                        (item) => this.cards[item].active
                    );

                    if (this.cards.setup.active || noActiveCard)
                        return findStatusId("Iniciado");

                    return findStatusId("Executando");
                }

                if (modal === "setup") return findStatusId("Setup");
                if (modal === "finish")
                    return findStatusId("Operação Finalizada");
            })();

            return currentStatus;
        },
        async confirmAppointment(data) {
            const currentDateFormat =
                this.$cookies.get("userInfo").language === "pt-BR"
                    ? "DD-MM-YYYY HH:mm"
                    : "MM-DD-YYYY HH:mm";

            const event_time = moment(
                data.date.value,
                currentDateFormat
            ).format("YYYY-MM-DD HH:mm");

            const statusId = this.getSelectedStatusId(data);
            const is_rework = Number(this.appointmentType);
            const modal = this.modal.type.toLowerCase();

            await this.createNewAppointment(
                statusId,
                event_time,
                is_rework == "1"
                    ? 0
                    : data.produced.value
                    ? String(data.produced.value)
                    : "",
                data.rejection.value ? String(data.rejection.value) : "",
                data.rework.value ? String(data.rework.value) : "",
                data.reason.id ? String(data.reason.id) : "",
                String(data.resource.id),
                "",
                is_rework
            );
            await this.loadPage(modal == "finish" ? 0 : is_rework);
            this.closeAppointmentModal();
        },
        redirectToOrderDetails() {
            // Redirect to order details modal
            if (this.isMultipleOrders) {
                this.showModalOrderDetailsList = true;
            } else {
                this.showModalOrder = true;
            }
            this.showOverflowProductionProgress = false;
        },
        formatDatetime(date) {
            if (!date) return null;

            const language = String(this.$i18n.locale);
            const PT_FORMAT = "DD-MM-YYYY HH:mm";
            const EN_FORMAT = "MM-DD-YYYY HH:mm";

            if (
                date instanceof Date ||
                moment.isMoment(date) ||
                moment(date).isValid()
            ) {
                // Se for objeto Date do JS ou for objeto Moment
                if (language == "pt-BR") {
                    return moment(date).format(PT_FORMAT);
                }

                return moment(date).format(EN_FORMAT);
            }
            // Se for formatado da própria aplicação (mudança de idioma)
            if (language === "pt-BR") {
                return moment(date, EN_FORMAT).format(PT_FORMAT);
            }
            return moment(date, PT_FORMAT).format(EN_FORMAT);
        },
        resetTimer() {
            clearInterval(this.timer.interval);
            this.timer.startingTime = null;
            this.timer.elapsedTime = null;
        },
        formatTimer(str) {
            return str.toString().padStart(2, "0");
        },
        createTimer(startingTime = 0) {
            const alreadyHasTimerRunning = !!this?.timer?.interval;

            if (alreadyHasTimerRunning) this.resetTimer();

            this.timer.startingTime = moment(startingTime);
            this.timer.elapsedTime =
                moment().valueOf() - this.timer.startingTime.valueOf();

            this.timer.interval = setInterval(() => {
                this.timer.elapsedTime += 1000;

                const { elapsedTime } = this.timer;
                const second = this.formatTimer(
                    Math.floor(elapsedTime / 1000) % 60
                );
                const minute = this.formatTimer(
                    Math.floor(elapsedTime / 1000 / 60) % 60
                );
                const hour = this.formatTimer(
                    Math.floor(elapsedTime / 1000 / 60 / 60)
                );

                this.timer.data = {
                    hour,
                    minute,
                    second,
                };
            }, 1000);
        },
        defineStatus(status) {
            switch (status) {
                case "Iniciado":
                case "Started":
                    return "started";
                case "Não Iniciado":
                    return "not-started";
                case "Setup":
                    return "setup";
                case "Executando":
                case "Running":
                    return "running";
                case "Completed":
                case "Operação Finalizada":
                    return "finished";
                default:
                    return "stopped";
            }
        },
        setCardsTimestamps(lastActivities) {
            this.cards.setup.datetime = this.formatDatetime(
                lastActivities.setup
            );
            this.cards.startPartial.datetime = lastActivities.running
                ? this.formatDatetime(lastActivities.running)
                : this.formatDatetime(lastActivities.start);
            this.cards.stop.datetime = this.formatDatetime(lastActivities.stop);
            this.cards.finish.datetime = this.formatDatetime(
                lastActivities.finished
            );
        },
        setFormDefaultValues() {
            const orderInfo = this.isMultipleOrders
                ? this.multiple
                : this.order;

            // Definindo valores padrão para datepicker da modal (Setup e Start)
            this.defaultDate.setup =
                orderInfo.setup_start &&
                moment(orderInfo.setup_start).isBefore(new Date())
                    ? moment(orderInfo.setup_start).toDate()
                    : new Date();

            this.defaultDate.start =
                orderInfo.start_time &&
                moment(orderInfo.start_time).isBefore(new Date())
                    ? moment(orderInfo.start_time).toDate()
                    : new Date();
        },
        setCardsData() {
            // Definindo apontamento individual / em massa
            const orderInfo = this.isMultipleOrders
                ? this.multiple
                : this.order;

            // Definindo os dados dos cards
            const status = this.defineStatus(
                this.isControlByHour
                    ? orderInfo.last_status_by_user
                    : orderInfo.last_status
            );

            let startingTime = null;
            let cardsToShow = ["setup", "start", "stop"];

            const lastActivities = {
                start: this.isControlByHour
                    ? orderInfo.last_started_by_user
                    : orderInfo.last_started,
                running: this.isControlByHour
                    ? orderInfo.last_running_by_user
                    : orderInfo.last_running,
                finished: this.isControlByHour
                    ? orderInfo.last_completed_by_user
                    : orderInfo.last_completed,
                setup: this.isControlByHour
                    ? orderInfo.last_setup_by_user
                    : orderInfo.last_setup,
                stop: this.isControlByHour
                    ? orderInfo.last_stopped_by_user
                    : orderInfo.last_stopped,
            };

            this.lastActivities = lastActivities;

            const active = (() => {
                if (status === "not-started") return false;

                if (status === "started") {
                    startingTime = lastActivities.start;
                    return "start";
                }

                if (status === "running") {
                    startingTime = lastActivities.running;
                    return "start";
                }

                if (status === "finished") {
                    startingTime = lastActivities.finished;
                    cardsToShow = ["finish"];
                    return "finish";
                }

                if (status === "setup") {
                    startingTime = lastActivities.setup;
                    return "setup";
                }

                startingTime = lastActivities.stop;
                return "stop";
            })();

            startingTime && this.createTimer(startingTime);
            this.timer.card = active;

            // Card Finalizar só aparece caso esteja executando ou finalizado
            const showCard = (card) =>
                card === "finish"
                    ? ["finish", "start"].includes(active)
                    : cardsToShow.includes(card);

            // Definindo regras e valores dos cards
            this.cards = {
                setup: {
                    show: showCard("setup"),
                    active: active === "setup",
                },
                startPartial: {
                    show: showCard("start"),
                    active: active === "start",
                },
                stop: {
                    show: showCard("stop"),
                    active: active === "stop",
                },
                finish: {
                    show: showCard("finish"),
                    active: active === "finish",
                },
            };

            // Definindo horários de último apontamento e valores padrão de formulários
            this.setCardsTimestamps(lastActivities);
            this.setFormDefaultValues();
        },
        async createNewAppointment(
            status,
            event_time,
            produced_quantity,
            refuse,
            rework,
            rejection_reason_id,
            resource_id,
            code_refuse = "",
            is_rework
        ) {
            if (this.idsNotFound) {
                for (const value of this.idsNotFound) {
                    let ids = this.preactorsOrdersId.split(",");
                    let index = ids.indexOf(value);
                    while (index !== -1) {
                        ids.splice(index, 1);
                        index = ids.indexOf(value, index);
                    }
                    this.preactorsOrdersId = ids.join(",");
                }
            }
            const body = this.isMultipleOrders
                ? {
                      user_id: this.user_id,
                      site_guid: this.currentSite,
                      resource_id,
                      status_id: status,
                      produced_quantity,
                      refuse,
                      rework,
                      event_time,
                      reject_reason_id: rejection_reason_id,
                      code_refuse,
                      preactor_order_id: this.preactorsOrdersId,
                      is_rework,
                  }
                : {
                      order_id: this.order.id,
                      preactor_order_id: this.order.preactor_order_id,
                      site_guid: this.order.site_guid,
                      op_no: this.order.op_no,
                      user_id: this.user_id,
                      status_id: status,
                      order_no: this.order.order_no,
                      resource_id,
                      event_time,
                      produced_quantity,
                      refuse,
                      rework,
                      percent_completed: 0,
                      rejection_reason_id,
                      is_rework,
                  };

            await (this.isMultipleOrders
                ? this.createMultipleAppointment(body)
                : this.createSingleAppointment(body));
        },
        async createSingleAppointment(body) {
            const POST_CREATE_SINGLE_APPOINTMENT_URL = `/api/${this.$router.currentRoute.params.prefix}/operation-progress`;

            try {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                await this.$http.post(POST_CREATE_SINGLE_APPOINTMENT_URL, body);

                if (this.isMultipleOrders) {
                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        this.$t(
                            "ProductionProgress.OperationProgressRegistred"
                        ),
                        "success"
                    );
                    return;
                }

                this.showToast(
                    this.$t("ProductionProgress.Toasts.Success.Title"),
                    "FrownIcon",
                    this.$t("ProductionProgress.Toasts.Success.Content"),
                    "success"
                );
            } catch (error) {
                this.showToast(
                    this.$t("Error"),
                    "FrownIcon",
                    this.$t(error.response.data.message),
                    "danger"
                );
            } finally {
                this.removeRequestLoading();
            }
        },
        async createMultipleAppointment(body) {
            const POST_CREATE_MULTIPLE_APPOINTMENT_URL = `/api/${this.$router.currentRoute.params.prefix}/operator/operation-progress/multiple`;

            try {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                await this.$http.post(
                    POST_CREATE_MULTIPLE_APPOINTMENT_URL,
                    body
                );

                if (this.isMultipleOrders) {
                    this.showToast(
                        this.$t("ProductionProgress.Success"),
                        "FrownIcon",
                        this.$t(
                            "ProductionProgress.OperationProgressRegistred"
                        ),
                        "success"
                    );
                    return;
                }

                this.showToast(
                    this.$t("ProductionProgress.Success"),
                    "FrownIcon",
                    `${this.$t(
                        "ProductionProgress.OperationProgressRegistred"
                    )}: ${this.$t("ProductionProgress.Order")} ${
                        this.order.order_no
                    }`,
                    "success"
                );
            } catch (error) {
                this.sendingForm = false;
                this.idsNotFound = error.response.data.idsNotFound;
                let toastText = "";
                if (this.idsNotFound) {
                    const orderNotFound = this.idsNotFound.map((id) => {
                        return this.multiple.orders.find(
                            (order) => order.preactor_order_id === id
                        );
                    });
                    const getOrderNamesById = orderNotFound
                        .map((order) => `${order.order_no}/${order.op_no}`)
                        .join(", ");
                    if (orderNotFound.length > 1) {
                        toastText = `${this.$t(
                            "ProductionProgress.Toasts.OrderNotFoundPlural1"
                        )} ${getOrderNamesById}${this.$t(
                            "ProductionProgress.Toasts.OrderNotFoundPlural2"
                        )}`;
                    } else if (orderNotFound.length === 1) {
                        toastText = `${this.$t(
                            "ProductionProgress.Toasts.OrderNotFoundSingular1"
                        )} ${getOrderNamesById} ${this.$t(
                            "ProductionProgress.Toasts.OrderNotFoundSingular2"
                        )}`;
                    }
                } else {
                    toastText = error.response.data.message;
                }

                this.showToast(
                    this.$t("Error"),
                    "FrownIcon",
                    toastText,
                    "danger"
                );
            } finally {
                this.removeRequestLoading();
            }
        },
        async getResources(useLoading = true) {
            this.resourcesList = [];
            const GET_RESOURCES_URL = `/api/${this.$router.currentRoute.params.prefix}/resource/workcenters?site_guid=${this.currentSite}`;

            try {
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                const response = await this.$http.get(GET_RESOURCES_URL);
                const data = response.data.response;
                const { isMultipleOrders } = this;

                const resources = data
                    .filter((resource) =>
                        isMultipleOrders ? !resource?.control_by_hour : true
                    )
                    ?.map((resource) => ({
                        text: resource?.alias ?? resource.name,
                        value: resource.preactor_resource_id,
                        allow_change_resource: resource?.allow_change_resource,
                        request_date_time: resource.request_date_time,
                        index: resource.display_sequence_number,
                    }));

                resources.sort((a, b) => a.index - b.index);

                this.resourcesList = resources;

                this.isControlByHour = data.find(
                    (element) =>
                        element.preactor_resource_id ==
                        this.$router.currentRoute.params.resource_id
                )?.control_by_hour;

                this.allowChangeResource = data.find(
                    (element) =>
                        element.preactor_resource_id ==
                        this.$router.currentRoute.params.resource_id
                )?.allow_change_resource;

                this.requestDatetime = data.find(
                    (element) =>
                        element.preactor_resource_id ==
                        this.$router.currentRoute.params.resource_id
                )?.request_date_time;
            } catch (error) {
                console.error(error);
            } finally {
                useLoading && this.removeRequestLoading();
            }
        },
        async getOrders(useLoading = true, is_rework = 0) {
            let GET_ORDERS_URL = `api/${this.$router.currentRoute.params.prefix}/operator/orders/multiple?site_guid=${this.currentSite}&preactor_orders_ids=${this.preactorsOrdersId}&is_rework=${is_rework}`;

            try {
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                const response = await this.$http.get(GET_ORDERS_URL);
                const data = response.data.response;

                this.multiple = data;
                this.resource = {
                    value: data.resource_id,
                    text: data.resource,
                };

                this.multipleOrdersList = this.multiple.orders.map((order) => ({
                    name: `${order.order_no}/${order.op_no}`,
                    id: order.preactor_order_id,
                }));
            } catch (error) {
                console.error(error);
            } finally {
                this.setCardsData();
                useLoading && this.removeRequestLoading();
            }
        },
        async getOrder(useLoading = true, is_rework = 0) {
            const GET_ORDER_URL = `/api/${this.$router.currentRoute.params.prefix}/order/${this.$router.currentRoute.params.order_id}?site_guid=${this.currentSite}&is_rework=${is_rework}`;
            try {
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                const response = await this.$http.get(GET_ORDER_URL);
                const data = response.data.response;
                this.order = data;

                this.resource = {
                    value: data.resource_id,
                    text: data.resource_name,
                };

                this.createListOfNameAndPreactorOfOrders();

                this.producedQuantity = data?.produced_quantity
                    ? Number(data.produced_quantity)
                    : 0;
                this.plannedQuantity = data?.planned_quantity
                    ? Number(data.planned_quantity)
                    : 0;
                this.remainingQuantity = Math.max(
                    this.plannedQuantity - this.producedQuantity,
                    0
                );

                const convertPercentage = (number) =>
                    `${(parseFloat(number) * 100).toFixed(0)}%`;

                this.percentProductive = convertPercentage(
                    this.order.completed_progress
                );
                this.percentCompleted = convertPercentage(
                    this.order.time_consumed
                );
                this.progressoPrevisto = convertPercentage(
                    this.order.theoretical_progress
                );
                this.progressoReal = convertPercentage(
                    this.order.real_progress
                );
            } catch (error) {
                console.error(error);
            } finally {
                useLoading && this.removeRequestLoading();
            }
        },
        async getCurrentlyStatus(useLoading = true) {
            const GET_CURRENTLY_STATUS_URL = `/api/${this.$router.currentRoute.params.prefix}/currently-status?site_guid=${this.currentSite}`;

            try {
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                const response = await this.$http.get(GET_CURRENTLY_STATUS_URL);
                const data = response.data.response;
                this.statusList = data.map((status) => ({
                    text: status.name,
                    value: status.id,
                    color: status.color,
                }));
                this.statusOptions = data
                    .filter(
                        (status) =>
                            status.status_classification != 0 &&
                            status.name !== "Setup"
                    )
                    .map((status) => ({ text: status.name, value: status.id }));
            } catch (error) {
                console.error(error);
            } finally {
                useLoading && this.removeRequestLoading();
            }
        },
        async getAttributesFields(useLoading = true) {
            const GET_ATTRIBUTES_FIELDS_URL = `/api/${this.$router.currentRoute.params.prefix}/attribute-field?site_guid=${this.currentSite}`;

            try {
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                const response = await this.$http.get(
                    GET_ATTRIBUTES_FIELDS_URL
                );
                this.attributesFields = response.data.response;
            } catch (error) {
                console.error(error);
            } finally {
                useLoading && this.removeRequestLoading();
            }
        },
        async getRefuseMotiveList(useLoading = true) {
            const GET_REFUSE_MOTIVE_LIST_URL = `/api/${this.$router.currentRoute.params.prefix}/refuse-status?site_guid=${this.currentSite}`;

            try {
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                const response = await this.$http.get(
                    GET_REFUSE_MOTIVE_LIST_URL
                );
                const data = response.data.response;
                this.refuseStatus = data.map((status) => ({
                    text: `${status.code} - ${status.name}`,
                    value: status.id,
                }));
            } catch (error) {
                console.error(error);
            } finally {
                useLoading && this.removeRequestLoading();
            }
        },
        createListOfNameAndPreactorOfOrders() {
            const {
                order,
                isMultipleOrders,
                order: { orders },
            } = this;

            if (isMultipleOrders) {
                const list = orders.map(
                    ({ preactor_order_id, order_no, op_no }) => ({
                        preactorOrderId: preactor_order_id,
                        orderLabel: `${order_no}/${op_no}`,
                    })
                );

                this.listOfNameAndPreactorOfOrders = [...list];
                return list;
            }

            const { preactor_order_id, order_no, op_no } = order;

            const list = [
                {
                    id: preactor_order_id,
                    name: `${order_no}/${op_no}`,
                },
            ];

            this.listOfNameAndPreactorOfOrders = [...list];
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        showSwal(title, icon, text) {
            return this.$swal({
                title,
                text,
                icon,
                showCancelButton: true,
                confirmButtonText: "Yes!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            });
        },
        async loadPage(is_rework) {
            this.isMultipleOrders =
                this.$router.currentRoute.params.multiple?.toString() ===
                "true";

            this.infoOperacao =
                this.$router.currentRoute.params.multiple != "true";

            this.selectedOrders = this.isMultipleOrders
                ? this.$router.currentRoute.params.orders
                : [];

            this.preactorsOrdersId =
                this.$router.currentRoute.params.preactor_orders;

            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            await (this.isMultipleOrders
                ? this.getOrders(false, is_rework)
                : this.getOrder(false, is_rework));
            await this.getResources(false);
            await this.getCurrentlyStatus(false);
            await this.getAttributesFields(false);
            await this.getRefuseMotiveList(false);
            this.setCardsData();
            this.removeRequestLoading();
        },
        handleLanguageChange() {
            this.setCardsTimestamps(this.lastActivities);
        },
    },
    data() {
        return {
            showModalOrderDetailsList: false,
            multipleOrdersList: [],
            multiple: {
                orders: [],
                status: {
                    name: "",
                    color: "",
                },
            },
            idsNotFound: [],
            listOfNameAndPreactorOfOrders: [],
            resource: {
                value: null,
                text: null,
            },
            producedQuantity: 0,
            plannedQuantity: 0,
            remainingQuantity: 0,
            defaultDate: {
                setup: "",
                start: "",
            },
            statusObject: {},
            timer: {
                card: "",
                data: {
                    hour: "00",
                    minute: "00",
                    second: "00",
                },
                interval: null,
                startingTime: 1686669022126,
            },
            resourcesList: [],
            isMultipleOrders: false,
            resource: null,
            isControlByHour: false,
            allowChangeResource: false,
            requestDatetime: false,

            percentProductive: null,
            percentCompleted: null,
            progressoPrevisto: null,
            progressoReal: null,

            infoOperacao: null,
            selectedOrders: null,
            preactorsOrdersId: null,

            attributesFields: null,

            refuseStatus: [],

            statusList: [],
            statusOptions: [],

            user_id: JSON.parse(localStorage.getItem("userData")).guid,

            currentSite: this.$cookies.get("userInfo").currentSite,
            prefix:
                localStorage.getItem("prefix") ||
                this.$router.currentRoute.params.prefix,

            modal: {
                opened: false,
                type: "",
            },
            order: {
                last_status_by_user: "",
                last_color_status_by_user: "",
                order_no: "",
                op_no: "",
            },
            cards: {
                setup: {
                    show: true,
                    active: false,
                },
                startPartial: {
                    show: true,
                    active: false,
                },
                stop: {
                    show: true,
                    active: false,
                },
                finish: {
                    show: true,
                    active: false,
                },
            },
            showModalOrder: false,
            showOverflowProductionProgress: true,
        };
    },
    async created() {
        await this.loadPage(this.$router.currentRoute.params.appointment_type);
    },
    mounted() {
        this.$watch("$i18n.locale", function () {
            this.handleLanguageChange();
        });
    },
};
</script>

<style lang="scss">
body:has(.hide-scroll) {
    overflow: hidden !important;
}

.app-content-operator:has(#production-progress) {
    padding: 0 !important;
}
</style>

<style lang="scss" scoped>
#production-progress {
    // Adaptando ao container app-content
    width: 100%;
    min-height: 84vh;

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 92vh;
        width: inherit;
        padding: 124px 24px 24px;

        .activity-cards {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 30px;
            margin-bottom: 24px;
        }
    }
}
.two-rows {
    grid-template-columns: 1fr 1fr !important;
}
.single-row {
    grid-template-columns: 1fr !important;
}
#pdp__button-c {
    margin-top: auto;
}
@media (max-width: 1100px) {
    .activity-cards {
        display: grid !important;
    }
}

@media (max-width: 768px) and (min-width: 714px) {
    #production-progress {
        .content-container {
            height: auto;
            padding-top: 222px;
        }
    }
}

@media (max-width: 714px) and (min-width: 480px) {
    #production-progress {
        .content-container {
            height: auto;
            padding-top: 270px;
        }
    }
}

@media (max-width: 550px) {
    .activity-cards {
        grid-template-columns: 1fr !important;
    }
}

@media (max-width: 480px) {
    .content-container {
        padding-top: 250px !important;
        padding-bottom: 11px !important;
        height: auto !important;

        #pdp__button-c {
            width: 100%;
            padding: 16px;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 99;
        }
    }
}
</style>
