var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
            {
                'hide-scroll':
                    !_vm.showOverflowProductionProgress || _vm.modal.opened,
            } ],attrs:{"id":"production-progress"}},[_c('AppointmentHeader',{attrs:{"component-id":'appointment-screen',"back-label":_vm.headerBackLabel,"back-event":_vm.headerBackEvent,"orders":_vm.headerOrders,"status":_vm.headerStatus,"color":_vm.headerColor},on:{"removeAppointment":_vm.removeAppointmentFromMultiple}}),_c('main',{staticClass:"content-container"},[_c('div',{class:[
                    'activity-cards',
                    _vm.moreThanOneCardShowing ? 'two-rows' : 'single-row' ]},[(_vm.cards.setup.show)?_c('ActivityCard',{attrs:{"title":_vm.$t('ProductionProgress.Setup'),"type":"setup","show-add-button":false,"active":_vm.cards.setup.active,"datetime":_vm.cards.setup.datetime,"timer":_vm.timer.card === 'setup' ? _vm.timer.data : null},on:{"handleClick":function($event){return _vm.openAppointmentModal('Setup')}}}):_vm._e(),(_vm.cards.startPartial.show)?_c('ActivityCard',{attrs:{"title":_vm.timer.card === 'stop'
                            ? _vm.$t('ProductionProgress.Continue')
                            : _vm.$t('ProductionProgress.Start'),"custom-active-label":_vm.$t('ProductionProgress.Partial'),"type":"start","show-add-button":"","active":_vm.cards.startPartial.active,"datetime":_vm.cards.startPartial.datetime,"timer":_vm.timer.card === 'start' ? _vm.timer.data : null},on:{"handleClick":function($event){return _vm.openAppointmentModal(
                            ("" + (_vm.cards.startPartial.active
                                    ? 'Partial'
                                    : 'Start'))
                        )}}}):_vm._e(),(_vm.cards.stop.show)?_c('ActivityCard',{attrs:{"title":_vm.$t('ProductionProgress.Stop'),"type":"stop","show-add-button":"","active":_vm.cards.stop.active,"datetime":_vm.cards.stop.datetime,"timer":_vm.timer.card === 'stop' ? _vm.timer.data : null},on:{"handleClick":function($event){return _vm.openAppointmentModal('Stop')}}}):_vm._e(),(_vm.cards.finish.show)?_c('ActivityCard',{attrs:{"title":_vm.cards.finish.active
                            ? _vm.$t('ProductionProgress.Finished')
                            : _vm.$t('ProductionProgress.Finish'),"type":"finish","show-add-button":false,"active":_vm.cards.finish.active,"datetime":_vm.cards.finish.datetime},on:{"handleClick":function($event){_vm.cards.finish.active
                            ? ''
                            : _vm.openAppointmentModal('Finish')}}}):_vm._e()],1),_c('div',{attrs:{"id":"pdp__button-c"}},[_c('Button',{attrs:{"title":_vm.$t('ProductionProgress.OrderDetails'),"icon":"orderDetails"},on:{"handleClick":_vm.redirectToOrderDetails}})],1)])],1),_c('AppointmentModal',{ref:"appointmentModal",attrs:{"allow-change-date":_vm.requestDatetime,"is-control-by-hour":_vm.isControlByHour,"order-object":_vm.isMultipleOrders ? _vm.multiple : _vm.order,"format-datetime":_vm.formatDatetime,"default-produced-quantity":_vm.remainingQuantity,"default-date":_vm.defaultDate,"default-resource":_vm.resource,"allow-change-resource":_vm.allowChangeResource,"resources-list":_vm.resourcesList,"status-list":_vm.statusOptions,"rejection-list":_vm.refuseStatus,"open":_vm.modal.opened,"title":_vm.returnAppointmentModalTitle(),"show-toast":_vm.showToast,"isMultipleOrders":_vm.isMultipleOrders,"orderListDisplay":_vm.isMultipleOrders
                ? _vm.multipleOrdersList
                : _vm.listOfNameAndPreactorOfOrders,"appointmentType":_vm.appointmentType},on:{"removeAppointment":_vm.removeAppointmentFromMultiple,"update:open":function($event){return _vm.$set(_vm.modal, "opened", $event)},"close":_vm.closeAppointmentModal,"confirmAppointment":_vm.confirmAppointment}}),(_vm.showModalOrder)?_c('OrderDetailsModal',{attrs:{"open":_vm.showModalOrder,"orderData":_vm.order,"isControlByHour":_vm.isControlByHour},on:{"update:open":function($event){_vm.showModalOrder=$event},"close":_vm.onCloseDetailsModal}}):_vm._e(),(_vm.showModalOrderDetailsList)?_c('OrderDetailsListModal',{attrs:{"open":_vm.showModalOrderDetailsList,"orderList":_vm.multiple.orders.map(function (order) { return ({
                preactorOrderId: order.preactor_order_id,
                orderLabel: ((order.order_no) + "/" + (order.op_no)),
            }); })},on:{"update:open":function($event){_vm.showModalOrderDetailsList=$event},"close":_vm.onCloseDetailsModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }